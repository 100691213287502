import {toAbsoluteUrl} from '../../../_ePulse/helpers'
import '../../../App.css'
import {DateRangePicker} from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {useState} from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux'
export const Statics = (props: any) => {
  const reduxData: any = useSelector((state) => {
    return state
  })

  const [state, setState] = useState([
    {
      // startDate: new Date(),
      // endDate: addDays(new Date(), 30),
      endDate: new Date(reduxData?.endDate),
      startDate: new Date(reduxData?.startDate),
      key: 'selection',
    },
  ])
  const [datePickerState, setDatePickerState] = useState(false)

  const closeModal = () => {
    // setState([
    //   {
    //     startDate: new Date(),
    //     endDate: addDays(new Date(), 30),
    //     key: 'selection',
    //   },
    // ])
    setDatePickerState(!datePickerState)
  }

  const updateDate = (item: any) => {
    setState([item.selection])
    props.dateState([item.selection])
  }

  const applyDate = () => {
    setDatePickerState(!datePickerState)
  }

  return (
    <>
      {/* <ToastMessageContainer /> */}
      <div className='d-flex flex-column w-100'>
        <div className='dashboard-header mb-4'>
          <h2 className='page-title'>Overview</h2>
          <div className='modalcontainer'>
            <div
              className='ePulse-datePickerParent bg-white p-3 rounded mb-3 '
              onClick={() => setDatePickerState(!datePickerState)}
            >
              <img
                src={toAbsoluteUrl('/media/ePulse/calendar.png')}
                className='h-54 align-self-center me-4'
                alt=''
              />
              {moment(state[0].startDate).format('YYYY-MM-DD')} -{' '}
              {moment(state[0].endDate).format('YYYY-MM-DD')}
            </div>
            {datePickerState && (
              <div className='bg-white d-flex flex-column modalCalendar rounded '>
                <DateRangePicker
                  onChange={(item: any) => updateDate(item)}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction='horizontal'
                />
                <div className='d-flex flex-row justify-content-end '>
                  {/* <button
                    className='rounded ms-2 ePulse-appColor-primary text-white border-1 border-light px-5 me-5'
                    onClick={() => applyDate()}
                  >
                    Apply
                  </button> */}
                  <button className='rounded border-1 px-5' onClick={() => closeModal()}>
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
