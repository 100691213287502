/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {checkPermission} from '../../../../app/App'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title='Dashboard'
        icon='/media/ePulse/icons/aside/dashboard.svg'
        heading='Home'
        className='py-3'
      ></AsideMenuItem>
      {(checkPermission('getAdminUserList') ||
        checkPermission('getSystemRoleList') ||
        checkPermission('getSystemModuleList') ||
        checkPermission('getErrorLogList') ||
        checkPermission('getEnrollReqUserList') ||
        checkPermission('getSystemRoleList')) && (
        <AsideMenuItemWithSubMain
          to='/adminsection'
          title='Admin'
          icon='/media/ePulse/icons/aside/user.svg'
          heading='Admin'
        >
          {checkPermission('getSystemRoleList') && (
            <AsideMenuItem to='/roles_permissions' title='Roles & Permissions' />
          )}
          {checkPermission('getAdminUserList') && <AsideMenuItem to='/admin' title='Admin' />}
          {/* {checkPermission('getSystemRoleList') && (
            <AsideMenuItem to='/system_role' title='System Role' />
          )} */}
          {/* {checkPermission('getRoleModuleList') && ( */}
          {/* <AsideMenuItem to='/super_admin_role' title='Super Admin Roles' /> */}
          {/* )} */}
          {checkPermission('getSystemModuleList') && (
            <AsideMenuItem to='/system_module' title='System Module' />
          )}
          {checkPermission('getErrorLogList') && (
            <AsideMenuItem to='/error_logging' title='Error Logging' />
          )}
          {checkPermission('getEnrollReqUserList') && (
            <AsideMenuItem to='/website_enquiry' title='Marketing Website Enquiry' />
          )}
        </AsideMenuItemWithSubMain>
      )}
      {(checkPermission('getApplicationSettings') ||
        checkPermission('getPaginationPolicyList') ||
        checkPermission('getPaginationHolidayList') ||
        checkPermission('getApprovalStepsPaginationList') ||
        checkPermission('getNotificationTemplateListByType')) && (
        <AsideMenuItemWithSubMain
          to='/settingssection'
          title='Settings'
          icon='/media/ePulse/icons/aside/setting.svg'
          heading='Settings'
        >
          {checkPermission('getApplicationSettings') && (
            <AsideMenuItem
              to='/application_setting/auth'
              title='Application Setting'
              // bsTitle='Application Setting'
            />
          )}
          {checkPermission('getPaginationPolicyList') && (
            <AsideMenuItem
              to='/application_policy'
              title='Application Policies'
              // bsTitle='Application Policies'
            />
          )}
          {checkPermission('getPaginationHolidayList') && (
            <AsideMenuItem
              to='/holiday_settings'
              title='Holidays Setting'
              // bsTitle='Holidays Setting'
            />
          )}
          {checkPermission('getApprovalStepsPaginationList') && (
            <AsideMenuItem
              to='/approval_business_steps'
              title='Approval/Business Steps'
              // bsTitle='Approval/Business Steps'
            />
          )}
          {checkPermission('getNotificationTemplateListByType') && (
            <AsideMenuItem
              to='/notification_setting/template'
              title='Notification'
              // bsTitle='Application Policies'
            />
          )}
        </AsideMenuItemWithSubMain>
      )}
      {/* {(checkPermission('getPaginationPlanList') ||
        checkPermission('getPaginationServiceList') ||
        checkPermission('getAddonPaginationServiceList') ||
        checkPermission('getPlanServiceList') ||
        checkPermission('getPaginationPlanPricingList')) && (
        <AsideMenuItemWithSubMain
          to='/plansection'
          title='Billing'
          fontIcon='bi-sticky'
          icon='/media/ePulse/icons/aside/bill.svg'
          // bsTitle='Billing'
          heading='Billing'
        >
          {checkPermission('getPaginationPlanList') && <AsideMenuItem to='/plan' title='Plan' />}
          {checkPermission('getPaginationServiceList') && (
            <AsideMenuItem to='/service' title='Service' />
          )}
          {checkPermission('getAddonPaginationServiceList') && (
            <AsideMenuItem to='/addon_service' title='Add-On Service' />
          )}
          {checkPermission('getPlanServiceList') && (
            <AsideMenuItem to='/servicetoplan' title='Add Service to Plan' />
          )}
          {checkPermission('getPaginationPlanPricingList') && (
            <AsideMenuItem to='/planfrequency' title='Plan Frequency Pricing' />
          )}
        </AsideMenuItemWithSubMain>
      )} */}
      {(checkPermission('getPaginationRxTemplateList') ||
        checkPermission('getNotCertifiedDoctorList') ||
        checkPermission('getNotCertifiedDoctorList') ||
        checkPermission('getDoctorSpecialtyList') ||
        checkPermission('getStateCouncilList') ||
        checkPermission('getMedicineList') ||
        checkPermission('getDiseaseList') ||
        checkPermission('getSymptomList') ||
        checkPermission('getPaginationAdviceList')) && (
        <AsideMenuItemWithSubMain
          to='/medicals'
          title='Medicine'
          fontIcon='bi-sticky'
          icon='/media/ePulse/icons/aside/rx-medicine.svg'
          heading='Doctor'
        >
          {checkPermission('getPaginationRxTemplateList') && (
            <AsideMenuItem
              to='/rx_module'
              title='RX Template'
              // bsTitle='Application Setting'
            />
          )}
          {checkPermission('getNotCertifiedDoctorList') && (
            <AsideMenuItem to='/non_approved_doctor' title='Non-Approved Doctors' />
          )}
          {checkPermission('getDoctorSpecialtyList') && (
            <AsideMenuItem to='/doctor_speciality' title='Doctor Speciality' />
          )}
          {checkPermission('getStateCouncilList') && (
            <AsideMenuItem to='/state_council' title='State Council' />
          )}
          {checkPermission('getMedicineList') && (
            <AsideMenuItem to='/rx-medicine' title='RX Medicine' />
          )}
          {checkPermission('getDiseaseList') && (
            <AsideMenuItem to='/disease' title='Diseases/Diagnosis' />
          )}
          {checkPermission('getSymptomList') && <AsideMenuItem to='/symptom' title='Symptoms' />}
          {checkPermission('getPaginationLaboratoryTestList') && (
            <AsideMenuItem to='/test_requested' title='Test Requested' />
          )}
          {checkPermission('getPaginationAdviceList') && (
            <AsideMenuItem to='/advice' title='Advice' />
          )}
        </AsideMenuItemWithSubMain>
      )}
      {/* {
        // checkPermission('') ||
        (checkPermission('getOrderPaymentHistoryList') ||
          checkPermission('getBusinessTransactionList') ||
          checkPermission('getSettlementList') ||
          checkPermission('getEpulsePaymentFeesList')) && ( */}
          <AsideMenuItemWithSubMain
            to='/summary'
            title='Summary'
            fontIcon='bi-sticky'
            icon='/media/ePulse/icons/aside/inventory.png'
            heading='Transactions'
          >
            {/* {checkPermission('getSymptomList') &&  */}
            {/* <AsideMenuItem to='/summary' title='Summary' /> */}
            {/* } */}
            {/* {checkPermission('getOrderPaymentHistoryList') && ( */}
              <AsideMenuItem to='/payments' title='Payments' />
             {/* )}
             {checkPermission('getBusinessTransactionList') && ( */}
              <AsideMenuItem to='/transactions' title='Transactions' />
             {/* )}
             {checkPermission('getSettlementList') && ( */}
              <AsideMenuItem to='/settlements' title='Settlements' />
             {/* )}
             {checkPermission('getEpulsePaymentFeesList') && ( */}
              <AsideMenuItem to='/fees-setting' title='Fees Setting' />
             {/* )} */}

            {/* <AsideMenuItem to='/refunds' title='Refunds' />
        <AsideMenuItem to='/dispute' title='Dispute' /> */}
          </AsideMenuItemWithSubMain>
        {/* )
      } */}
    </>
  )
}
